
import React, { useEffect, useState } from "react";
import { TextField, Button, Typography } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import './App.css';
import { useNavigate } from 'react-router-dom';

export default function PowerAutomateLogin() {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const expectedUsername = 'cemadmin';
    const expectedPassword = '6S!Fo$f27*8';
    const loginSuccessMsg = 'Authentication successful!';
    const loginFailedMsg = 'Invalid login credentials. Please check your username and password and try again.';
    const Flows = "/Flows";

    useEffect(() => {
        localStorage.removeItem("authenticated");
    }, []);

    const Success = (popupMsg) => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: popupMsg,
            buttonsStyling: true,
        });
    };

    const Warning = (popupMsg) => {
        Swal.fire({
            icon: "warning",
            title: "Error",
            text: popupMsg,
            buttonsStyling: true,
        });
    };

    const handleLogin = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username');
        const password = data.get('password');

        if (username === expectedUsername && password === expectedPassword) {
            Success(loginSuccessMsg);
            setAuthenticated(true);
            localStorage.setItem("authenticated", true);
            navigate(Flows);
        } else {
            setAuthenticated(false);
            localStorage.setItem("authenticated", false);
            Warning(loginFailedMsg);
        }
    };

    return (
        <div className="container-login">
            <img src="/images/swaas_logo.png" alt="Logo" className="logo" />
            <form className="form" onSubmit={handleLogin}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Login
                </Typography>
                <br />
                <TextField
                    className="formControl"
                    name="username"
                    label="Username"
                    variant="outlined"
                    required
                    autoFocus
                />
                <br /><br />
                <TextField
                    className="formControl"
                    name="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    required
                />
                <br /><br />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<LockOutlined />}
                >
                    Login
                </Button>
            </form>
        </div>
    );
}
