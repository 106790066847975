import './App.css';
import Flows from './Flows';
import PowerAutomateLogin from './login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
function App() {

  return (
    <Router>
      <Routes>
        <Route index element={<PowerAutomateLogin />} />
        <Route path="/login" element={<PowerAutomateLogin />} />
        <Route path="/flows" element={<Flows />} />
      </Routes>
    </Router>
  );
}

export default App;
