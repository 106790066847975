import React, { useState } from 'react';
import Box from '@mui/material/Box';
import './App.css';
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import axios from 'axios';

function Flows() {
    const loggedInUser = localStorage.getItem("authenticated");
    const PowerAutomateAPIURL = process.env.REACT_APP_POWER_AUTOMATE_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [hdEmail, hdSetEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [hdIsEmailValid, hdSetIsEmailValid] = useState(false);

    //swal alert function for success
    const Success = (popupMSg) => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: popupMSg,
            buttonsStyling: true,

        });
    };

    //swal alert function for warning
    const Warning = (popupMSg) => {
        Swal.fire({
            icon: "warning",
            title: "Error",
            text: popupMSg,
            buttonsStyling: true,

        });
    };
    const toggleLoading = (status) => {
        setIsLoading(status);
    };
    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        validateEmail(value);
    };
    const handleHDEmailChange = (event) => {
        const value = event.target.value;
        hdSetEmail(value);
        validateHDEmail(value);
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailRegex.test(email));
    };
    const validateHDEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        hdSetIsEmailValid(emailRegex.test(email));
    };
    const rows = [
        { id: 1, Flow: 'Payslip', Action: 'Start' },
        { id: 2, Flow: 'TP Reports', Action: 'Start' },
        { id: 3, Flow: 'Birthday and service alerts', Action: 'Start' },
        { id: 4, Flow: 'HD Data Migration', Action: 'Start' }

    ];
    const columns = [
        { field: 'Flow', headerName: 'Flow ', width: 500 },
        {
            field: 'Action',
            headerName: 'Action',
            width: 500,
            // renderCell: (params) => (
            //     <Button
            //         variant="contained"
            //         color="primary"
            //         onClick={() => handleButtonClick(params)}
            //     >
            //         {params.value}
            //     </Button>
            // ),
            renderCell: (params) => (
                <>
                    {params.row.id === 2 && (
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            label="Email"
                            placeholder='Enter Email'
                            value={email}
                            onChange={handleEmailChange}
                            error={!isEmailValid && email !== ''}
                            helperText={!isEmailValid && email !== '' ? 'Invalid email' : ''}
                            // fullWidth
                            style={{ marginRight: '5px' }}
                        />
                    )}
                    {params.row.id === 4 && (
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            label="Email"
                            placeholder='Enter Email'
                            value={hdEmail}
                            onChange={handleHDEmailChange}
                            error={!hdIsEmailValid && hdEmail !== ''}
                            helperText={!hdIsEmailValid && hdEmail !== '' ? 'Invalid email' : ''}
                            // fullWidth
                            style={{ marginRight: '5px' }}
                        />
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleButtonClick(params)}
                        disabled={(params.row.id === 2 && !isEmailValid) || (params.row.id === 4 && !hdIsEmailValid)}
                    >
                        {params.value}
                    </Button>
                </>
            ),
        },
    ];

    const handleButtonClick = (params) => {
        debugger;
        toggleLoading(true);
        console.log(email);
        console.log(params);
        console.log(params.id);
        if (params.row.id === 1) {
            axios.post(PowerAutomateAPIURL,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
                .then(response => {
                    if (response.status === 200 && response.data === "Success") {
                        Success("Flow run successfully");
                        toggleLoading(false);


                    }
                    else if (response.status === 200 && response.data === "Failed") {
                        Warning("Current flow got failed, please check and rerun.");
                        toggleLoading(false);

                    }
                })
                .catch(error => {
                    console.error(error);
                    toggleLoading(false);

                    Warning("Error in schema. please Check the json.");
                });

        } else if (params.row.id === 2) {
            const params = {
                "Email": email
            }
            axios.post("https://prod-00.centralindia.logic.azure.com:443/workflows/71b7eb9209cf49df94b9cb9a9efbebb8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-QaZM1vLy8A2wzq3ZRvq8RRafPwivNzn-40Ts0AmXr4", params
            )
                .then(response => {
                    if (response.status === 200 && response.data === "Success") {
                        Success("Flow run successfully");
                        toggleLoading(false);


                    }
                    else if (response.status === 200 && response.data === "Failed") {
                        Warning("Current flow got failed, please check and rerun.");
                        toggleLoading(false);

                    }
                })
                .catch(error => {
                    console.error(error);
                    toggleLoading(false);

                    Warning("Error in schema. please Check the json.");
                });
        } else if (params.row.id === 3) {
            axios.get("https://prod-04.centralindia.logic.azure.com:443/workflows/ef8870f134ff4d6c9fa6c8e53d60810f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Q74OlizUquNDqZ9tQEwiTFjNhJttIPnDDlQBTzSMK6E"
            )
                .then(response => {
                    if (response.status === 200 && response.data === "Success") {
                        Success("Flow run successfully");
                        toggleLoading(false);


                    }
                    else if (response.status === 200 && response.data === "Failed") {
                        Warning("Current flow got failed, please check and rerun.");
                        toggleLoading(false);

                    }
                })
                .catch(error => {
                    console.error(error);
                    toggleLoading(false);

                    Warning("Error in schema. please Check the json.");
                });
        }
        // else if (params.row.id === 4) {
        //     const token = 'rt_9BAFCE68C1852D03016F0C0A6DF1460B0FC917FECCDA6AE70F40480927AD1A2D-1';
        //     const params = {
        //         "startInfo": {
        //             "ReleaseKey": "5bde3f6f-73db-4a0f-9919-cadab7a9cf33",
        //             "JobsCount": 1,
        //             "Strategy": "ModernJobsCount",
        //             "RobotIds": [],
        //             "NoOfRobots": 0
        //         }
        //     }
        //     axios.post("https://cloud.uipath.com/swaasdevuipath/DefaultTenant/orchestrator_/odata/Jobs/UiPath.Server.Configuration.OData.StartJobs", params
        //         , {
        //             headers: {
        //                 'X-UIPATH-OrganizationUnitId': 1,
        //                 'X-UIPATH-FolderPath': 'HD Data Migration Demo',
        //                 'Accept': 'application/json',
        //                 'Authorization': `Bearer ${token}`
        //             }
        //         }
        //     )
        //         .then(response => {
        //             toggleLoading(false);
        //             Success("Flow Started Succssfully");
        //             console.log(response);
        //         })
        //         .catch(error => {
        //             console.error(error);
        //             toggleLoading(false);
        //             Warning("Error in schema. please Check the json.");
        //         });
        // }
        else if (params.row.id === 4) {
            const params = {
                "Email": hdEmail
            }
            axios.post("https://prod-19.centralindia.logic.azure.com:443/workflows/e4e64e6491d44eafb7b7e891aaf69bd7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=EGEZmQDc4EwJvAEKX_VB-huhbOVZUvHJzYfeO4MUY2I", params
            )
                .then(response => {
                    if (response.status === 200 && response.data === "Success") {
                        Success("Flow run successfully");
                        toggleLoading(false);


                    }
                    else if (response.status === 200 && response.data === "Failed") {
                        Warning("Current flow got failed, please check and rerun.");
                        toggleLoading(false);

                    }
                })
                .catch(error => {
                    console.error(error);
                    toggleLoading(false);

                    Warning("Error in schema. please Check the json.");
                });
        }

    };
    if (!loggedInUser) {
        return <Navigate to="/login" />;

    } else {
        return (
            <Box sx={{ padding: '1rem' }}>
                {isLoading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999
                        }}
                    >
                        <CircularProgress color="primary" />
                    </Box>
                )}
                <div className="container">
                    <h1>Power Automate Flows</h1>

                    <div className="dataGrid">
                        <DataGrid rows={rows} columns={columns} pageSizeOptions={false} hideFooterPagination
                        />
                    </div>
                </div>
            </Box>
        );
    }
}

export default Flows;
